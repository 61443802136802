import isomorphicFetch from 'isomorphic-fetch'
import fetchRetryBuilder from 'fetch-retry'
import isServerSideRendering from './isServerSideRendering'

const fetch = fetchRetryBuilder(isomorphicFetch)

type IRequestInitWithRetry = NonNullable<Parameters<typeof fetch>[1]>

const fetchRetryConfig: IRequestInitWithRetry = {
  retries: 0,
  retryDelay: 1000,
}

const fetchSaveFile = async (
  urlOriginal: RequestInfo,
  initOriginal?: RequestInit
): Promise<Response> => {
  const baseUrl = isServerSideRendering()
    ? process.env.SAVE_PRIVATE_API_URL
    : process.env.GATSBY_SAVE_PUBLIC_API_URL
  const url = `${baseUrl}${urlOriginal}`

  const authHeaders = isServerSideRendering()
    ? { Authorization: `Bearer ${process.env.SAVE_PRIVATE_API_TOKEN}` }
    : undefined

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    ...authHeaders,
    ...(initOriginal ? initOriginal.headers : undefined),
  }

  if (
    'Content-Type' in headers &&
    headers['Content-Type'].trim().length === 0
  ) {
    delete headers['Content-Type']
  }

  const init: NonNullable<typeof initOriginal> & IRequestInitWithRetry =
    initOriginal
      ? {
          ...initOriginal,
          ...fetchRetryConfig,
          headers,
        }
      : { headers }

  return await fetch(url, init)
}

export default fetchSaveFile
