import { useStaticQuery, graphql } from 'gatsby'
import { SaveShopsQuery } from '../_generated/codegen/graphqlTypes'
import notEmpty from '../utils/notEmpty'
import { Unpacked } from '../utils/Unpacked'

export type ISaveShop = NonNullable<
  Unpacked<SaveShopsQuery['allSaveShop']['nodes']>
>

const useSaveShopsQuery = (): ISaveShop[] => {
  const { allSaveShop }: SaveShopsQuery = useStaticQuery(
    graphql`
      query SaveShops {
        allSaveShop(
          sort: { fields: orderLetter, order: ASC }
          filter: { isWeb: { eq: true } }
        ) {
          nodes {
            shopId
            shopName
            shopFullAddress
            longitude
            latitude
            horaireDimanche
            horaireJeudi
            horaireLundi
            horaireMardi
            horaireMercredi
            horaireSamedi
            horaireVendredi
            exceptionnelClosing
            exceptionnelOpening
            orderLetter
            isWeb
            samsungLevel
            appleLevel
          }
        }
      }
    `
  )
  return allSaveShop.nodes.filter(notEmpty) ?? []
}

export default useSaveShopsQuery
