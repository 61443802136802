import React from 'react'
import useCustomerReviewsQuery from "../../hooks/useCustomerReviewsQuery";
import ReviewsCard from "./ReviewsCard";


const CustomerReviews = ({ shopId }) => {
    const reviews = useCustomerReviewsQuery(shopId)

    if (!reviews || reviews.length === 0) {
        return null;
    }

    return (
        <div className="customer-reviews">
            <div className="reviews-title-container">
                <span className="reviews-title">L'expérience SAVE de nos clients</span>
            </div>
            <div className="review-list">
                {reviews.map((review) => (
                    <ReviewsCard
                        key={review.date}
                        date={review.date}
                        subtitle={review.subtitle}
                        name={review.name}
                        content={review.content}
                        shopId={review.shopId}
                        rating={review.rating}
                    />
                ))}
            </div>
        </div>

    )
}

export default CustomerReviews
