import React from 'react';
import BookingContent from "../../../../components/BookingContent";

interface BookingConfirmationProps {
    modele: string;
    shopName: string;
    shopAddress: string;
    handleCloseToHome: () => void;
}

const BookingConfirmation: React.FC<BookingConfirmationProps> = ({
                                                                     modele,
                                                                     shopName,
                                                                     shopAddress,
                                                                     handleCloseToHome,
                                                                 }) => {
    return (
        <BookingContent.Modal>
            <BookingContent.ModalContent>
                <BookingContent.Close onClick={handleCloseToHome}>
                    &times;
                </BookingContent.Close>
                <div style={{ textAlign: "center" }}>
                    <BookingContent.Title style={{
                        marginTop: '30px',
                        display: 'flex',
                        gap: '20px',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <img src="/images/smartphone/confirmation.svg" alt={""} style={{width: "50px"}}/>
                        <p>Votre réservation a bien été envoyée à notre boutique.</p>
                    </BookingContent.Title>
                    <h2 style={{ marginTop: "20px" }}>N’attendez plus et venez chercher votre reconditionné dans un délai de 24h</h2>
                    <h2 style={{marginTop: "5px"}}>avant que votre réservation soit expirée ! </h2>
                    <BookingContent.BookingSummary>
                        <img src="/images/smartphone/shop_icon.svg" alt={""}/>
                        <span>Votre {modele} est disponible à</span>
                        <span><img src="/images/save.svg" alt={"saveIcon"}/>{shopName}</span>
                        <span><img src="/images/smartphone/location.svg" alt={"locationIcon"}/>{shopAddress}</span>
                    </BookingContent.BookingSummary>
                    <br/>
                    <BookingContent.Button onClick={handleCloseToHome}>Fermer</BookingContent.Button>
                </div>
            </BookingContent.ModalContent>
        </BookingContent.Modal>
    );
}

export default BookingConfirmation;
