import React from 'react';
import './SmartphoneCard.css'

class ReviewsCard extends React.Component<{
    date: any,
    subtitle: any,
    name: any,
    content: any,
    shopId: any,
    rating: any
}> {
    render() {
        let {subtitle, name, content} = this.props;
        return (
            <div className="testimonial-card">
                <div className="quote-mark">“</div>
                <div className="testimonial-content">{content}</div>
                <div className="testimonial-footer">
                    <p className="testimonial-name">{name}</p>
                    <p className="testimonial-subtitle">{subtitle}</p>
                </div>
            </div>
        );
    }
}

export default ReviewsCard;
