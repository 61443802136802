import * as yup from 'yup'
import {BookingFormData} from "../models/BookingFormData";

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const BookingFormSchema: yup.SchemaOf<BookingFormData> = yup
    .object({
        // shop
        shopId: yup.string().required('Un magasin doit être selectionné'),
        // contact
        lastName: yup.string().required('Le nom de famille est requis'),
        firstName: yup.string().required('Le prénom est requis'),
        email: yup
            .string()
            .email('L’adresse mail est invalide')
            .required('L’adresse mail est requise'),
        optin1: yup.boolean(),
        optin2: yup.boolean(),
        phone: yup.string().matches(phoneRegExp, {
            message: 'Phone number is not valid',
            excludeEmptyString: true,
        }),
    })
    .defined()

export default BookingFormSchema
