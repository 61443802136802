export const setLocalStorage = (key: string, value: any) => {
    const item = {
        value: value,
        timestamp: Date.now()
    };
    localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorage = <T>(key: string): T | null => {
    const cacheDuration = 3600000; // 1 hour in milliseconds
    try {
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        if (item.timestamp + cacheDuration < Date.now()) {
            localStorage.removeItem(key); // Remove expired item
            return null;
        }

        return item.value as T;
    } catch (error) {
        console.error(`Error parsing localStorage value for key "${key}":`, error);
        return null;
    }
};