import { FieldError } from 'react-hook-form'
import { useEffect, useState } from 'react'

const useFormError = (
  isSubmitting: boolean
): [
  FieldError | undefined,
  React.Dispatch<React.SetStateAction<FieldError | undefined>>
] => {
  const [formError, setFormError] = useState<FieldError | undefined>(undefined)

  useEffect(() => {
    if (isSubmitting) {
      setFormError(undefined)
    }
  }, [isSubmitting])

  return [formError, setFormError]
}

export default useFormError
