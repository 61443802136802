import fetchSaveFile from './fetchSaveFile'
import isServerSideRendering from './isServerSideRendering'

const fetchSaveApi = async <T>(
  url: RequestInfo,
  init?: RequestInit
): Promise<T | undefined> => {
  const resp = await fetchSaveFile(url, init)
  const text = await resp.text()
  try {
    if (resp.status !== 200) {
      console.warn(resp)
      throw new Error("Une erreur s'est produite")
    }
    return text === '' ? {} : JSON.parse(text)
  } catch (err) {
    if (isServerSideRendering()) {
      console.warn(text)
      console.warn(err)
    }
    return undefined
  }
}

export default fetchSaveApi
