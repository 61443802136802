export const customEncode = (str: string) => {
    return encodeURIComponent(str)
        .replace(/%20/g, '-')  // Replace URL-encoded space (%20) with hyphen
        .replace(/%2D/g, '--') // Replace URL-encoded hyphen (%2D) with double hyphen
};

export const customDecode = (str: string) => {
    return decodeURIComponent(str
        .replace(/--/g, '%2D') // Replace double hyphens back to URL-encoded hyphen
        .replace(/-/g, '%20')) // Replace hyphens back to URL-encoded space
        .replace(/%2B/g, '+'); // Replace hyphens back to URL-encoded space
};