import fetchSaveApi from "./fetchSaveApi";

export interface Smartphone {
    buyAmountTTC: number;
    capacity: string;
    color: string;
    grade: string;
    marque: string;
    modele: string;
    photo: string;
    quantity: number;
    reference: string;
    shopId: string;
}

interface smartphoneStockListResponse {
    smartphoneStockList: Smartphone[];
}

export interface BookingResponse {
    modele: string;
    shopId: string;
}

export const fetchSmartphoneStock = async (shopId: string) => {
    try {
        const resp: smartphoneStockListResponse | undefined = await fetchSaveApi('/website/v1/smartphone/stock/' + shopId, {
            method: 'GET',
            headers: {
                'Content-Type': '',
            },
        });

        if (!resp) {
            return [];
        } else {
            return (resp.smartphoneStockList || [])
                .filter(phone => {
                    return phone!==null && phone!==undefined &&
                        phone.capacity!==null && phone.capacity!==undefined &&
                        phone.color!== null && phone.color!==undefined &&
                        phone.grade!== null && phone.grade!==undefined
                });
        }
    } catch (error) {
        console.error("Error fetching smartphone stock:", error);
        return [];
    }

};