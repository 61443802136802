import { useStaticQuery, graphql } from 'gatsby'
import { CustomerReviewQuery } from '../_generated/codegen/graphqlTypes'
import { Unpacked } from '../utils/Unpacked'
import notEmpty from '../utils/notEmpty'

export type ICustomerReviewsQuery = NonNullable<
    Unpacked<CustomerReviewQuery['strapi']['customerReviews']>
>

const useCustomerReviewsQuery = (shopId?: string): ICustomerReviewsQuery[] => {
    const { strapi }: CustomerReviewQuery = useStaticQuery(
        graphql`
      query customerReview {
        strapi {
          customerReviews {
            date
            subtitle
            name
            content
            shopId
            rating
          }
        }
      }
    `
    )

    const reviews = strapi.customerReviews?.filter(notEmpty) ?? []
    const defaultReviews = reviews.filter(review => review.shopId === "default").slice(0, 3)

    if (shopId) {
        const shopReviews = reviews.filter(review => review.shopId === shopId).slice(0, 3)
        if (shopReviews.length > 0) {
            return shopReviews
        }
    }

    return defaultReviews
}

export default useCustomerReviewsQuery
