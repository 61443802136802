import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

interface Props {
  size?: 'small' | 'normal'
  color?: 'muted'
  variant?: 'normal' | 'outline'
}

const button = css<Props>`
  text-decoration: none;
  border-radius: 39px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  display: block;
  font-size: 13px;
  line-height: 16px;
  padding: 8px 15px;
  border: none;
  transition: background-color 300ms ease;
  background: ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.secondary};
  }

  ${({ color }) => {
    return (
      color === 'muted' &&
      css`
        background: ${({ theme }) => theme.colors.placeholder};
      `
    )
  }}

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 18px;
    line-height: 22px;
    padding: 15px 20px;
  }

  ${({ size }) => {
    return (
      size === 'small' &&
      css`
        font-size: 12px;
        line-height: 13px;
        padding: 4px 8px;

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: 13px;
          line-height: 16px;
          padding: 10px 15px;
        }
      `
    )
  }}

  ${({ variant }) => {
    return (
      variant === 'outline' &&
      css`
        box-shadow: inset 0 0 0 3px ${({ theme }) => theme.colors.primary};
        background-color: transparent;
        color: ${({ theme }) => theme.colors.primary};

        &:hover {
          background-color: ${({ theme }) => theme.colors.primary};
          color: #fff;
        }
      `
    )
  }}
`

const Button = styled.button`
  ${button}
`

const ButtonLink = styled(Link)`
  ${button}
`

const ButtonExternalLink = styled.a`
  ${button}
`

export default Object.assign(Button, {
  Link: ButtonLink,
  ExternalLink: ButtonExternalLink,
})
