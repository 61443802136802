import React, {useState} from 'react';
import useSaveShopsQuery from "../../hooks/useSaveShopsQuery";
import {customEncode} from "../../utils/manageUrl";

type SmartphoneCardProps = {
    reference: string;
    marque: string;
    modele: string;
    buyAmountTTC: number | null;
    grade: string | null;
    capacity: string | null;
    quantity: number;
    color: string | null;
    photo: string | null;
    shopId: string | null;
};

    const SmartphoneCard: React.FC<SmartphoneCardProps> = ({
                                                           marque,
                                                           modele,
                                                           buyAmountTTC,
                                                           photo,
                                                           shopId,
                                                       }) => {
    const shops = useSaveShopsQuery();
    const ShopIndex = shops.findIndex((x) => x.shopId === shopId);
    const shop = shops[ShopIndex];
    let pathname = typeof window !== "undefined" ? window.location.pathname : ""
    const currentShopId =  pathname.split('/')[2];

    const [isHovered, setIsHovered] = useState(false);

    const url = "/smartphone/" + shopId + "/" + customEncode(modele);
    const defaultImage = "https://asset-fr.trepidai.pro/model-information/SMA-DEFAULT.png"

        return (
            <a className="card" href={url} data-ga4-event="buy-model" data-ga4-param-value={`${marque} ${modele}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div className="card-content">
                <img src={photo || defaultImage} alt={modele} className="product-image"/>
                <div className="card-infos">
                    <h3 className="product-title">{marque?.toUpperCase()} {modele}</h3>
                    <p className="price">à partir de <strong>{buyAmountTTC} €</strong></p>
                    {currentShopId === shopId ? (
                        <p className="location">
                            <i className="location-icon"></i>
                            {shop?.shopName}
                        </p>
                    ) : (
                        <div className="tooltip-container">
                            <p className="location_Proche">
                                <i className="location-icon"></i>
                                Boutique proche
                                <i
                                    className="proche-info"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                ></i>
                            </p>
                            {isHovered && (
                                <div
                                    className="tooltip"
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    Boutique à moins de 20km de la boutique sélectionnée
                                </div>
                            )}
                        </div>
                    )}
                </div>
                </div>
                <div className="warranty">
                    <i className="warranty-icon"></i>
                    Garantie 2 ans
                </div>
            </a>
        );
};

export default SmartphoneCard;
