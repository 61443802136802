import React from 'react';
import BookingContent from "../../../../components/BookingContent";
import Button from "../../../../components/Button";
import {magasinLink} from "../../../../data/menu";

interface BookingErrorProps {
    modele: string;
    shopName: string;
    shopAddress: string;
    handleCloseToHome: () => void;
}

const BookingError: React.FC<BookingErrorProps> = ({
                                                       modele,
                                                       shopName,
                                                       handleCloseToHome
                                                   }) => {
    return (
        <BookingContent.Modal>
            <BookingContent.ModalContent>
                <BookingContent.Close onClick={handleCloseToHome}>
                    &times;
                </BookingContent.Close>
                <BookingContent.Title>Réservation de votre reconditionné</BookingContent.Title>
                <div style={{margin: "40px 0", textAlign:"center", lineHeight: "2"}}>
                    <h2>Vous avez déjà effectué une réservation en ligne pour le smartphone {modele} dans la boutique {shopName}.</h2>
                    <h2>Un peu de patience, vous pourrez faire une nouvelle réservation dans 24h :) !</h2>
                    <h2>En attendant vous pouvez vous rendre dans l'un de nos magasins Save afin d'acheter le smartphone de votre choix.</h2>
                    <a href={magasinLink} style={{textDecoration: "none"}}>
                        <Button style={{display: "inline", marginTop: "30px"}}>Me rendre en magasin</Button>
                    </a>
                </div>

            </BookingContent.ModalContent>
        </BookingContent.Modal>
    );
}

export default BookingError;
